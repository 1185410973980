import { createSlice } from "@reduxjs/toolkit"

type ModalState = {
  isOpen: boolean
  header?: string
  contentKey: null | "footer"
}

const initialState: ModalState = {
  isOpen: false,
  header: "",
  contentKey: null,
}

const slice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleModalOpen: (state, action) => {
      state.isOpen = !state.isOpen
      state.header = action.payload.header
      state.contentKey = action.payload.contentKey
    },
    openModal: (state, action) => {
      state.isOpen = true
      state.header = action.payload.header
      state.contentKey = action.payload.contentKey
    },
    closeModal: (state) => {
      state.isOpen = false
      state.contentKey = null
    },
  },
})

export const { toggleModalOpen, openModal, closeModal } = slice.actions
export const modalReducer = slice.reducer
