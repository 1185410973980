import React, { createContext, useContext, ReactNode, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { languages, Translations, translations } from "./translationsData"

interface LanguageContextType {
  currentLanguage: string
  setLanguage: (lang: string) => void
  t: (key: string) => string
}

export const LanguageContext = createContext<LanguageContextType | undefined>(undefined)

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation()
  const urlLanguage = location.pathname.split("/")[1]
  const storedLanguage = localStorage.getItem("language")
  const initialLanguage = (languages.includes(urlLanguage) && urlLanguage) || storedLanguage || "en"
  const [currentLanguage, setCurrentLanguage] = useState<string>(initialLanguage)

  globalThis.gettext = (key: string) => {
    return translate(key)
  }

  const setLanguage = (lang: string) => {
    setCurrentLanguage(lang)
  }

  useEffect(() => {
    localStorage.setItem("language", currentLanguage)
    updateMetaTags()
  }, [currentLanguage])

  const translate = (key: string): string => {
    const translation = translations[key] as Translations[keyof Translations]
    return translation ? translation[currentLanguage as keyof typeof translation] || translation.en || key : key
  }

  const updateMetaTags = () => {
    const pageTitle = document.querySelector("title")
    if (pageTitle) {
      pageTitle.innerText = translate("pageTitle")
    }

    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute("content", translate("metaDescription"))
    }

    const ogTitle = document.querySelector('meta[property="og:title"]')
    if (ogTitle) {
      ogTitle.setAttribute("content", translate("pageTitle"))
    }

    const ogDescription = document.querySelector('meta[property="og:description"]')
    if (ogDescription) {
      ogDescription.setAttribute("content", translate("metaDescription"))
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]')
    if (metaKeywords) {
      metaKeywords.setAttribute("content", translate("metaKeywords"))
    }
  }

  const contextValue: LanguageContextType = { currentLanguage, setLanguage, t: translate }

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>
}

export const useLanguage = () => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider")
  }

  return context
}
