import React from "react"
import style from "./AboutMe.module.scss"
import { Container } from "../../common/components/Container/Container"
import { Animation } from "../../common/components/Animation/Animation"
import { ComponentHeader } from "../../common/components/ComponentHeader/ComponentHeader"
import { useLanguage } from "../../common/translations/translations"

export const AboutMe = () => {
  const timeDiff = new Date().getTime() - new Date("2022-06-01").getTime()
  const dayDiff = timeDiff / (1000 * 3600 * 24)
  const yearDiff = Math.ceil(dayDiff / 365.25)

  const { t } = useLanguage()

  return (
    <section className={style.aboutMeBlock} id={"about"}>
      <Container>
        <div className={style.wrapper}>
          <Animation animation={"animate__fadeInUp"} threshold={0.2}>
            <div className={style.title}>
              <h3>{t("about_about_me")}</h3>
              <ComponentHeader>{t("about_know_me_more")}</ComponentHeader>
            </div>
          </Animation>

          <Animation animation={"animate__fadeInUp"} threshold={0.1}>
            <div className={style.infoBlock}>
              <div className={style.nameWrapper}>
                <div className={style.nameUnderlineWrapper}>
                  {t("about_hi")}
                  <span className={style.nameUnderline}>{t("about_name")}</span>
                </div>

                <p>{t("about_text")}</p>
              </div>

              <div className={style.numbers}>
                <div className={style.number}>{yearDiff}</div>
                <div className={style.circle}></div>
                <div className={style.numberText}>{t('about_years_of_experience')}</div>
              </div>
            </div>
          </Animation>
        </div>
      </Container>
    </section>
  )
}
