import { instance } from "../../common/api/common.api"

export const contactsApi = {
  sendMessage(data: FormData) {
    return instance.post("/send-form", data)
  },
}

export type FormData = {
  name: string
  email: string
  message: string
}
