import React, { FC } from "react"
import styles from "./Line.module.scss"
import { Animation } from "../../../common/components/Animation/Animation"

interface Props {
  skill: string
  percentage: number
}

export const Line: FC<Props> = ({ skill, percentage }) => {
  return (
    <div>
      <div className={styles.nameWrapper}>
        <p className={styles.skillName}>{skill}</p>
        <p className={styles.skillPercentage}>{percentage}%</p>
      </div>

      <div className={styles.line}>
        <Animation
          animation={"animate__zoomInRight"}
          threshold={0.4}
          className={styles.filledLine}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  )
}
