import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { LanguageProvider } from "./common/translations/translations"
import { Provider } from "react-redux"
import { store } from "./app/store"

import App from "./app/App"
import "./assets/fonts/fonts.scss"

import { Theme } from "./common/components/Theme/Theme"
import { Modal } from "./common/components/Modal/Modal"
import { Alert } from "./common/components/Alert/Alert"
import { ScrollToTop } from "./common/components/ScrollToTop/ScrollToTop"

import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <LanguageProvider>
      <Provider store={store}>
        <Theme />
        <App />
        <Modal />
        <Alert />
        <ScrollToTop />
      </Provider>
    </LanguageProvider>
  </BrowserRouter>
)

reportWebVitals()
