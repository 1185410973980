import React, { FC } from "react"
import s from "./Button.module.scss"

type ButtonProps = {
  children: React.ReactNode
  theme?: "primary" | "secondary"
  onClick?: () => void
  type?: "button" | "submit" | "reset"
  width?: string
  marginTop?: string
  padding?: string
  disabled?: boolean
}

export const Button: FC<ButtonProps> = ({ children, theme = "primary", onClick, width, marginTop, padding, type, disabled }) => {
  const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <button
      onClick={onClick}
      onContextMenu={handleContextMenu}
      className={`${s.btn} ${theme === "secondary" ? s.secondary : ""}`}
      type={type}
      disabled={disabled}
      style={{ width: width, marginTop: marginTop, padding: padding }}
    >
      {children}
    </button>
  )
}
