import React, { FC, useState } from "react"
import style from "./Skills.module.scss"
import { Skill } from "./Skill/Skill"
import { Container } from "../../common/components/Container/Container"
import { Animation } from "../../common/components/Animation/Animation"
import {
  SiReact,
  SiRedux,
  SiJavascript,
  SiTypescript,
  SiAxios,
  SiJest,
  SiSass,
  SiStyledcomponents,
  SiStorybook,
  SiMui,
  SiTestcafe,
  SiBackbonedotjs,
  SiNextdotjs,
  SiStylus,
  SiSocketdotio,
  SiMercurial,
  SiGithub,
  SiGit,
  SiNodedotjs,
  SiDocker,
  SiMysql,
  SiPixiv,
  SiXstate,
  SiFigma,
} from "react-icons/si"
import { IconType } from "react-icons/lib"
import { IoIosMore } from "react-icons/io"
import { ComponentHeader } from "../../common/components/ComponentHeader/ComponentHeader"
import { MdKeyboardDoubleArrowUp } from "react-icons/md"
import { useLanguage } from "../../common/translations/translations"

type SkillDataType = {
  Icon: IconType
  title: string
}

const skillData: SkillDataType[] = [
  { Icon: SiJavascript, title: "JavaScript" },
  { Icon: SiTypescript, title: "TypeScript" },
  { Icon: SiReact, title: "React" },
  { Icon: SiRedux, title: "Redux" },
  { Icon: SiBackbonedotjs, title: "Backbone.js" },
  { Icon: SiPixiv, title: "Pixi.js" },
  { Icon: SiXstate, title: "Xsate" },
  { Icon: SiNextdotjs, title: "Next.js" },
  { Icon: SiNodedotjs, title: "Node.js" },
  { Icon: SiSocketdotio, title: "Websoket" },
  { Icon: SiAxios, title: "Axios" },
  { Icon: SiFigma, title: "Figma" },
  { Icon: SiTestcafe, title: "Unit-tests" },
  { Icon: SiJest, title: "Jest" },
  { Icon: SiSass, title: "SASS" },
  { Icon: SiStylus, title: "Stylus" },
  { Icon: SiStyledcomponents, title: "Styled-Components" },
  { Icon: SiMui, title: "MUI" },
  { Icon: SiStorybook, title: "StoryBook" },
  { Icon: SiGit, title: "Git" },
  { Icon: SiGithub, title: "Github" },
  { Icon: SiMercurial, title: "Mercurial" },
  { Icon: SiDocker, title: "Docker" },
  { Icon: SiMysql, title: "MySQL" },
]

export const Skills: FC = () => {
  const initialCount = 12
  const categoryTabsStep = 12
  const countAllCategoryTabs = skillData.length
  const [categoryTabsEnd, setCategoryTabsEnd] = useState(Math.min(initialCount, countAllCategoryTabs))

  const staticSkill = skillData.slice(0, initialCount)
  const categoryTabsSegmented = skillData.slice(initialCount)

  const { t } = useLanguage()

  return (
    <section className={style.skillsBlock} id={"skills"}>
      <Container>
        <div className={style.wrapper}>
          <Animation animation={"animate__fadeInUp"} threshold={0.4}>
            <div className={style.title}>
              <h3>{t("skills_what")}</h3>
              <ComponentHeader>{t("skills_how")}</ComponentHeader>
            </div>
          </Animation>

          <div className={style.skills}>
            <div className={style.static}>
              {staticSkill.map((s, i) => {
                return (
                  <Animation key={i} animation={"animate__fadeInUp"} threshold={0.4}>
                    <Skill {...s} />
                  </Animation>
                )
              })}
            </div>

            <div className={`${style.other} ${categoryTabsEnd > 12 ? style.visible : ""}`}>
              {categoryTabsSegmented.map((s, i) => {
                return <Skill key={i} {...s} />
              })}
            </div>
          </div>

          {categoryTabsEnd < countAllCategoryTabs && (
            <Animation animation={"animate__fadeIn"} threshold={0.4}>
              <IoIosMore
                className={style.moreIcon}
                onClick={() => setCategoryTabsEnd(categoryTabsEnd + categoryTabsStep)}
              />
            </Animation>
          )}

          {categoryTabsEnd >= countAllCategoryTabs && (
            <Animation animation={"animate__fadeInUp"} threshold={0.4}>
              <MdKeyboardDoubleArrowUp className={style.moreIcon} onClick={() => setCategoryTabsEnd(initialCount)} />
            </Animation>
          )}
        </div>
      </Container>
    </section>
  )
}
