export interface Translation {
  en: string
  zh: string
  ru: string
  es: string
  pt: string
  be: string
}

export interface Translations {
  [key: string]: Translation
}

export const languages = ["en", "be", "es", "pt", "ru", "zh"]

export const translations: Translations = {
  metaDescription: {
    en: "Experienced frontend developer crafting user-friendly and visually appealing web applications.",
    zh: "经验丰富的前端开发者，创建用户友好且视觉吸引力十足的网页应用。",
    ru: "Опытный фронтенд-разработчик, создающий удобные и визуально привлекательные веб-приложения.",
    es: "Desarrollador frontend experimentado creando aplicaciones web amigables visualmente atractivas.",
    pt: "Desenvolvedor frontend experiente criando aplicações web amigáveis e visualmente atraentes.",
    be: "Вопытны фронтэнд-распрацоўнік, каторы стварае зручныя да карыстальнікаў і візуальна прывабныя вэб-прыкладанні.",
  },
  metaKeywords: {
    en: "Sergey Fedorovich, experienced frontend developer, React, Redux, PixiJS, web development, FEDRR.COM, JavaScript, HTML, CSS, TypeScript, web programming, software engineer, UX/UI design, front-end architecture, responsive design",
    zh: "Sergey Fedorovich, 经验丰富的前端开发者, React, Redux, PixiJS, 网页开发, FEDRR.COM, JavaScript, HTML, CSS, TypeScript, 网页编程, 软件工程师, UX/UI设计, 前端架构, 响应式设计",
    ru: "Сергей Федорович, опытный фронтенд-разработчик, React, Redux, PixiJS, веб-разработка, FEDRR.COM, JavaScript, HTML, CSS, TypeScript, веб-программирование, инженер-программист, UX/UI дизайн, архитектура фронтенда, адаптивный дизайн",
    es: "Sergey Fedorovich, desarrollador frontend con experiencia, React, Redux, PixiJS, desarrollo web, FEDRR.COM, JavaScript, HTML, CSS, TypeScript, programación web, ingeniero de software, diseño UX/UI, arquitectura frontend, diseño responsivo",
    pt: "Sergey Fedorovich, desenvolvedor frontend experiente, React, Redux, PixiJS, desenvolvimento web, FEDRR.COM, JavaScript, HTML, CSS, TypeScript, programação web, engenheiro de software, design UX/UI, arquitetura frontend, design responsivo",
    be: "Сяргей Федаровіч, вопытны фронтэнд-распрацоўнік, React, Redux, PixiJS, вэб-распрацоўнік, FEDRR.COM, JavaScript, HTML, CSS, TypeScript, вэб-праграмаванне, інжынер-праграміст, UX/UI дызайн, фронтэнд-архітэктура, адаптыўны дызайн",
  },
  pageTitle: {
    en: "Sergey Fedorovich - Frontend developer (React.js, Pixi.js, Next.js, Backbone.js) | FEDRR.COM",
    zh: "Sergey Fedorovich - 前端开发者 (React.js, Pixi.js, Next.js, Backbone.js) | FEDRR.COM",
    ru: "Сергей Федорович - Фронтенд-разработчик (React.js, Pixi.js, Next.js, Backbone.js) | FEDRR.COM",
    es: "Sergey Fedorovich - Desarrollador frontend (React.js, Pixi.js, Next.js, Backbone.js) | FEDRR.COM",
    pt: "Sergey Fedorovich - Desenvolvedor frontend (React.js, Pixi.js, Next.js, Backbone.js) | FEDRR.COM",
    be: "Сяргей Федаровіч - фронтэнд-распрацоўнік (React.js, Pixi.js, Next.js, Backbone.js) | FEDRR.COM",
  },
  main: {
    en: "Main",
    zh: "主页",
    ru: "Главная",
    es: "Principal",
    pt: "Principal",
    be: "Галоўная",
  },
  aboutMe: {
    en: "About me",
    zh: "关于我",
    ru: "Обо мне",
    es: "Acerca de mí",
    pt: "Sobre mim",
    be: "Пра мяне",
  },
  skills: {
    en: "Skills",
    zh: "技能",
    ru: "Навыки",
    es: "Habilidades",
    pt: "Habilidades",
    be: "Навыкі",
  },
  resume: {
    en: "Resume",
    zh: "简历",
    ru: "Резюме",
    es: "Currículum",
    pt: "Currículo",
    be: "Рэзюмэ",
  },
  contacts: {
    en: "Contacts",
    zh: "联系方式",
    ru: "Контакты",
    es: "Contactos",
    pt: "Contatos",
    be: "Кантакты",
  },
  // main
  main_hi: {
    en: "HI, I'M SERGEY FEDOROVICH",
    zh: "嗨，我是谢尔盖·费多罗维奇",
    ru: "ПРИВЕТ, Я СЕРГЕЙ ФЕДОРОВИЧ",
    es: "HOLA, SOY SERGEY FEDOROVICH",
    pt: "OI, EU SOU SERGEY FEDOROVICH",
    be: "Прывітанне, я Сяргей Федаровіч",
  },
  main_developer: {
    en: "developer",
    zh: "开发人员",
    ru: "разработчик",
    es: "desarrollador",
    pt: "desenvolvedor",
    be: "распрацоўнік",
  },
  main_react: {
    en: "React",
    zh: "React",
    ru: "React",
    es: "React",
    pt: "React",
    be: "React",
  },
  main_redux: {
    en: "Redux",
    zh: "Redux",
    ru: "Redux",
    es: "Redux",
    pt: "Redux",
    be: "Redux",
  },
  main_pixijs: {
    en: "PixiJS",
    zh: "PixiJS",
    ru: "PixiJS",
    es: "PixiJS",
    pt: "PixiJS",
    be: "PixiJS",
  },
  main_welcome: {
    en: "Welcome to my portfolio",
    zh: "欢迎访问我的作品集",
    ru: "Добро пожаловать в моё портфолио",
    es: "Bienvenido a mi portafolio",
    pt: "Bem-vindo ao meu portfólio",
    be: "Сардэчна запрашаю у маё партфоліа",
  },
  main_view_resume: {
    en: "View resume",
    zh: "查看简历",
    ru: "Резюме",
    es: "Ver currículum",
    pt: "Ver currículo",
    be: "Прагледзець рэзюмэ",
  },
  main_contact_me: {
    en: "Contact Me",
    zh: "联系我",
    ru: "Связаться",
    es: "Contáctame",
    pt: "Contate-me",
    be: "Звяжыцеся з мной",
  },
  // about
  about_about_me: {
    en: "About Me",
    zh: "关于我",
    ru: "Обо мне",
    es: "Acerca de mí",
    pt: "Sobre Mim",
    be: "Пра мяне",
  },
  about_know_me_more: {
    en: "Know me more",
    zh: "更多了解我",
    ru: "Познакомьтесь со мной ближе",
    es: "Conóceme más",
    pt: "Conheça-me mais",
    be: "Даведацца пра мяне больш",
  },
  about_hi: {
    en: "Hi, I'm",
    zh: "嗨，我是",
    ru: "Привет, я",
    es: "Hola, soy",
    pt: "Oi, eu sou",
    be: "Прывітанне, я",
  },
  about_name: {
    en: "Sergey Fedorovich",
    zh: "Sergey Fedorovich",
    ru: "Сергей Федорович",
    es: "Sergey Fedorovich",
    pt: "Sergey Fedorovich",
    be: "Сяргей Федаровіч",
  },
  about_text: {
    en: "A frontend developer, and my passion is creating something new and inspiring in the digital world. I love turning design concepts into functioning web pages and crafting interfaces that people genuinely find pleasing and easy to use. Every project for me is an opportunity to explore new technologies and employ fresh approaches to create faster and more intuitive websites. In my spare time, I enjoy exploring new frameworks and tools to stay abreast of the latest trends in development. My goal is not just to build websites but to create effective digital solutions that solve real problems and make users' lives easier.",
    zh: "我是一名前端开发者，我的激情是在数字世界中创造新的、启发人心的东西。我喜欢将设计概念转化为功能齐全的网页，并制作让人们真正喜欢和容易使用的界面。对我来说，每个项目都是探索新技术、采用新方法创建更快、更直观网站的机会。在业余时间，我喜欢探索新的框架和工具，以紧跟发展的最新趋势。我的目标不仅仅是构建网站，而是创造有效的数字解决方案，解决实际问题，让用户的生活更轻松。",
    ru: "Я фронтенд-разработчик, и моя страсть - создавать что-то новое и вдохновляющее в цифровом мире. Мне нравится превращать дизайн-концепции в функциональные веб-страницы и создавать интерфейсы, которые люди находят приятными и легкими в использовании. Каждый проект для меня - это возможность изучить новые технологии и использовать свежие подходы для создания более быстрых и интуитивно понятных веб-сайтов. В свободное время я люблю изучать новые фреймворки и инструменты, чтобы быть в курсе последних тенденций в разработке. Моя цель - не просто создавать веб-сайты, а создавать эффективные цифровые решения, решающие реальные проблемы и облегчающие жизнь пользователей.",
    es: "Soy un desarrollador frontend, y mi pasión es crear algo nuevo e inspirador en el mundo digital. Me encanta convertir conceptos de diseño en páginas web funcionales y diseñar interfaces que la gente encuentre realmente agradables y fáciles de usar. Cada proyecto para mí es una oportunidad para explorar nuevas tecnologías y emplear enfoques frescos para crear sitios web más rápidos e intuitivos. En mi tiempo libre, disfruto explorando nuevos frameworks y herramientas para estar al tanto de las últimas tendencias en desarrollo. Mi objetivo no es solo construir sitios web, sino crear soluciones digitales efectivas que resuelvan problemas reales y faciliten la vida de los usuarios.",
    pt: "Sou um desenvolvedor frontend, e minha paixão é criar algo novo e inspirador no mundo digital. Adoro transformar conceitos de design em páginas da web funcionais e criar interfaces que as pessoas realmente achem agradáveis e fáceis de usar. Cada projeto para mim é uma oportunidade de explorar novas tecnologias e empregar abordagens frescas para criar sites mais rápidos e intuitivos. No meu tempo livre, gosto de explorar novos frameworks e ferramentas para acompanhar as últimas tendências em desenvolvimento. Meu objetivo não é apenas construir sites, mas criar soluções digitais eficazes que resolvam problemas reais e facilitem a vida dos usuários.",
    be: "Я фронтэнд-распрацоўнік, і мой запал - стварэнне чаго-небудзь новага і натхняльнага ў цыфравым свеце. Мне падабаецца пераўтвараць дызайн-канцэпцыі ў функцыянальныя вэб-старонкі і ствараць інтэрфейсы, якія людзі знаходзяць прыемнымі і лёгкімі ў выкарыстанні. Кожны праект для мяне - гэта магчымасць даследаваць новыя тэхналогіі і выкарыстоўваць свежыя падыходы для стварэння хутчэйшых і інтуітыўна зразумелых вэб-сайтаў. У свабодны час я люблю даследваць новыя фрэймворкі і інструменты, каб быць у курсе апошніх тэндэнцый у распрацоўцы. Мая мэта - не проста ствараць вэб-сайты, а ствараць эфектыўныя лічбавыя рашэнні, якія вырашаюць рэальныя праблемы і палягчаюць жыццё карыстальнікаў.",
  },
  about_years_of_experience: {
    en: "Years of Experience",
    zh: "工作经验",
    ru: "Опыт работы",
    es: "Años de Experiencia",
    pt: "Anos de Experiência",
    be: "Гады вопыту",
  },
  // skills
  skills_what: {
    en: "What can I do?",
    zh: "我能做什么？",
    ru: "Что я умею?",
    es: "¿Qué puedo hacer?",
    pt: "O que eu posso fazer?",
    be: "Што я ўмею?",
  },
  skills_how: {
    en: "How I can help your next project",
    zh: "我如何帮助您的下一个项目",
    ru: "Чем я могу помочь вашему следующему проекту",
    es: "Cómo puedo ayudar en tu próximo proyecto",
    pt: "Como posso ajudar no seu próximo projeto",
    be: "Як я магу дапамагчы вашаму наступнаму праекту",
  },
  // resume
  resume_resume: {
    en: "Resume",
    zh: "简历",
    ru: "Резюме",
    es: "Currículum",
    pt: "Currículo",
    be: "Рэзюмэ",
  },
  resume_summary: {
    en: "A summary of My Resume",
    zh: "我的简历摘要",
    ru: "Резюме - кратко",
    es: "Un resumen de Mi Currículum",
    pt: "Um resumo do Meu Currículo",
    be: "Рэзюмэ - коратка",
  },
  resume_experience: {
    en: "My Experience",
    zh: "我的经验",
    ru: "Мой опыт",
    es: "Mi Experiencia",
    pt: "Minha Experiência",
    be: "Мой досвед",
  },
  resume_education: {
    en: "My Education",
    zh: "我的教育",
    ru: "Мое образование",
    es: "Mi Educación",
    pt: "Minha Educação",
    be: "Мая адукацыя",
  },
  resume_frontend_developer: {
    en: "Frontend Developer",
    zh: "前端开发人员",
    ru: "Фронтенд разработчик",
    es: "Desarrollador Frontend",
    pt: "Desenvolvedor Frontend",
    be: "Фронтэнд распрацоўнік",
  },
  resume_technical_support_engineer: {
    en: "Technical Support Engineer",
    zh: "技术支持工程师",
    ru: "Инженер технической поддержки",
    es: "Ingeniero de Soporte Técnico",
    pt: "Engenheiro de Suporte Técnico",
    be: "Інжынер тэхнічнай падтрымкі",
  },
  resume_current: {
    en: "Current",
    zh: "现在",
    ru: "Настоящее время",
    es: "Actual",
    pt: "Atual",
    be: "Цяперашні час",
  },
  resume_june: {
    en: "June",
    zh: "六月",
    ru: "Июнь",
    es: "Junio",
    pt: "Junho",
    be: "Чэрвень",
  },
  resume_july: {
    en: "July",
    zh: "七月",
    ru: "Июль",
    es: "Julio",
    pt: "Julho",
    be: "Ліпень",
  },
  resume_november: {
    en: "November",
    zh: "十一月",
    ru: "Ноябрь",
    es: "Noviembre",
    pt: "Novembro",
    be: "Лістапад",
  },
  resume_sportdata: {
    en: "Development of complex web applications using React, Backbone, JavaScript, and Mercurial for source code management, styling with Stylus to enhance the user interface, interaction with WebSockets, development and maintenance of class components. Game development using PixiJS, XState, TypeScript, including creating interactive elements, animations, working with game scenes, integrating audio and visual effects, and implementing complex game mechanics, as well as handling data related to betting and online casinos, ensuring their processing and display on the website.",
    zh: "使用React、Backbone、JavaScript和Mercurial开发复杂的Web应用程序，进行源代码管理，使用Stylus进行界面样式设计以增强用户体验，使用WebSockets进行交互，开发和维护类组件。使用PixiJS、XState和TypeScript进行游戏开发，包括创建交互式元素、动画，处理游戏场景，整合音频和视觉效果，并实现复杂的游戏机制，同时处理与赌博和在线赌场相关的数据，确保它们在网站上的处理和展示。",
    ru: "Разработка сложных веб-приложений с использованием React, Backbone, JavaScript и Mercurial для управления исходным кодом, стилизация интерфейса с помощью Stylus, работа с WebSockets, разработка и поддержка классовых компонентов, а также создание игр с использованием PixiJS, XState и TypeScript, включая интерактивные элементы, анимации, игровые сцены, интеграцию звуковых и визуальных эффектов и реализацию сложных игровых механик, а также обработка и отображение данных, связанных с онлайн-казино и ставками.",
    es: "Desarrollo de aplicaciones web complejas utilizando React, Backbone, JavaScript y Mercurial para la gestión del código fuente, estilizado con Stylus para mejorar la interfaz de usuario, interacción con WebSockets, desarrollo y mantenimiento de componentes de clase. Desarrollo de juegos utilizando PixiJS, XState, TypeScript, incluyendo la creación de elementos interactivos, animaciones, trabajo con escenas de juego, integración de efectos de audio y visual, e implementación de mecánicas de juego complejas, así como manejo de datos relacionados con apuestas y casinos en línea, asegurando su procesamiento y visualización en el sitio web.",
    pt: "Desenvolvimento de aplicações web complexas utilizando React, Backbone, JavaScript e Mercurial para gerenciamento de código-fonte, estilização com Stylus para aprimorar a interface do usuário, interação com WebSockets, desenvolvimento e manutenção de componentes de classe. Desenvolvimento de jogos utilizando PixiJS, XState, TypeScript, incluindo a criação de elementos interativos, animações, trabalho com cenas de jogo, integração de efeitos de áudio e visual, e implementação de mecânicas de jogo complexas, além de lidar com dados relacionados a apostas e cassinos online, garantindo seu processamento e exibição no site.",
    be: "Распрацоўка складаных вэб-прыкладанняў з выкарыстаннем React, Backbone, JavaScript і Mercurial для кіравання зыходным кодам, стылізацыя інтэрфейсу з дапамогай Stylus для паляпшэння взаімадзеяння з карыстальнікамі, праца з WebSockets, распрацоўка і падтрымка класавых кампанентаў. Стварэнне гульняў з выкарыстаннем PixiJS, XState і TypeScript, уключаючы стварэнне інтэрактыўных элементаў, анімацыі, праца з гульнявымі сцэнамі, інтэграцыя звукавых і візуальных эфектаў і рэалізацыя складаных гульнявых механік, а таксама апрацоўка і адлюстраванне дадзеных, звязаных з онлайн-казіно і стаўкамі.",
  },
  resume_telegif: {
    en: "Development of complex web applications using React, Redux, TypeScript, working with tables, large amounts of data, graphics, and images, implementation of infinite scroll, optimization of user interface, writing unit tests, working with AJAX for CRUD operations and data processing, experience with version control systems Git and GitLab.",
    zh: "使用React、Redux和TypeScript开发复杂的Web应用程序，处理表格、大量数据、图形和图片，实现无限滚动效果，优化用户界面并编写单元测试，使用AJAX进行CRUD操作和数据处理，具备Git和GitLab版本控制系统经验。",
    ru: "Разработка сложных веб-приложений с использованием React, Redux, TypeScript, работа с таблицами, большим объемом данных, графикой и изображениями, реализация бесконечной прокрутки, оптимизация пользовательского интерфейса и написание модульных тестов, работа с AJAX для CRUD операций и обработки данных, опыт с системами контроля версий Git и GitLab.",
    es: "Desarrollo de aplicaciones web complejas utilizando React, Redux, TypeScript, trabajo con tablas, grandes volúmenes de datos, gráficos e imágenes, implementación de scroll infinito, optimización de interfaz de usuario y escritura de pruebas unitarias, trabajo con AJAX para operaciones CRUD y procesamiento de datos, experiencia con sistemas de control de versiones como Git y GitLab.",
    pt: "Desenvolvimento de aplicações web complexas utilizando React, Redux, TypeScript, trabalho com tabelas, grandes quantidades de dados, gráficos e imagens, implementação de rolagem infinita, otimização de interface de usuário e escrita de testes unitários, trabalho com AJAX para operações CRUD e processamento de dados, experiência com sistemas de controle de versão como Git e GitLab.",
    be: "Распрацоўка складаных вэб-прыкладанняў з выкарыстаннем React, Redux, TypeScript, праца з табліцамі, вялікімі наборамі дадзеных, графікай і малюнкамі, рэалізацыя бясконечнага скролінгу, аптымізацыя інтэрфейсу карыстальніка і напісанне модульных тэставанняў, праца з AJAX для CRUD-аперацый і апрацоўкі дадзеных, вопыт з сістэмамі кантролю версій, такімі як Git і GitLab.",
  },
  resume_sys: {
    en: "Writing technical specifications, provided technical assistance and resolved software-related issues for users.",
    zh: "撰写技术规范，为用户提供技术支持，并解决与软件相关的问题。",
    ru: "Написание технических спецификаций, предоставление технической поддержки и решение проблем, связанных с программным обеспечением для пользователей.",
    es: "Redacción de especificaciones técnicas, prestación de asistencia técnica y resolución de problemas relacionados con el software para los usuarios.",
    pt: "Escrita de especificações técnicas, fornecimento de assistência técnica e resolução de problemas relacionados ao software para os usuários.",
    be: "Напісанне тэхнічных спецыфікацый, прадастаўленне тэхнічнай дапамогі і вырашэнне праблем, звязаных з праграмным забеспячэннем для карыстальнікаў.",
  },
  resume_master: {
    en: "Master in Economics",
    zh: "经济学硕士",
    ru: "Магистр экономики",
    es: "Máster en Economía",
    pt: "Mestrado em Economia",
    be: "Магістр па эканоміцы",
  },
  resume_bachelor: {
    en: "Bachelor in Economics",
    zh: "经济学学士",
    ru: "Бакалавр экономики",
    es: "Licenciatura en Economía",
    pt: "Bacharelado em Economia",
    be: "Бакалавр па эканоміцы",
  },
  resume_bseu: {
    en: "Belarus State Economic University",
    zh: "白俄罗斯国家经济大学",
    ru: "Белорусский государственный экономический университет",
    es: "Universidad Estatal Económica de Bielorrusia",
    pt: "Universidade Estadual de Economia da Bielorrússia",
    be: "Беларускі дзяржаўны эканамічны універсітэт",
  },
  resume_education_master: {
    en: "Completion of Master's degree in Economics. Gained knowledge and expertise in various economic theories, quantitative analysis, and statistical modeling.",
    zh: "完成经济学硕士学位。获得了各种经济理论、定量分析和统计建模的知识和专业知识。",
    ru: "Завершение обучения по программе магистратуры по экономике. Получение знаний и навыков в различных экономических теориях, количественном анализе и статистическом моделировании.",
    es: "Completó la maestría en Economía. Adquirió conocimientos y experiencia en diversas teorías económicas, análisis cuantitativo y modelado estadístico.",
    pt: "Conclusão do mestrado em Economia. Adquiriu conhecimentos e expertise em diversas teorias econômicas, análise quantitativa e modelagem estatística.",
    be: "Сканчэнне магістрантуры па эканоміцы. Набыццё ведаў і вопыту ў розных эканамічных тэорыях, колькасным аналізе і статыстычным мадэляванні.",
  },
  resume_education_bachelor: {
    en: "Actively engaging in research projects and academic conferences. Received Headmaster's award.",
    zh: "积极参与研究项目和学术会议。获得校长奖。",
    ru: "Активное участие в исследовательских проектах и академических конференциях. Получение награды ректора.",
    es: "Participación activa en proyectos de investigación y conferencias académicas. Recibió el premio del director.",
    pt: "Participação ativa em projetos de pesquisa e conferências acadêmicas. Recebeu o prêmio do diretor.",
    be: "Актыўнае ўдзел у даследчых праектах і навуковых канферэнцыях. Атрыманне награды рэктара.",
  },
  resume_my_skills: {
    en: "My Skills",
    zh: "我的技能",
    ru: "Мои навыки",
    es: "Mis Habilidades",
    pt: "Minhas Habilidades",
    be: "Мае навыкі",
  },
  resume_download_cv: {
    en: "Download CV",
    zh: "下载简历",
    ru: "Скачать резюме",
    es: "Descargar CV",
    pt: "Baixar CV",
    be: "Спампаваць рэзюмэ",
  },
  // contacts
  contacts_get_in_touch: {
    en: "Let's get in touch",
    zh: "让我们保持联系",
    ru: "Давайте останемся на связи",
    es: "Mantengámonos en contacto",
    pt: "Vamos manter contato",
    be: "Давайце застанемся на сувязі",
  },
  contacts_description_1: {
    en: "Thank you for visiting my portfolio page! If you are looking for an experienced and talented front-end developer who can help you create a unique and functional web interface, then you are in the right place.",
    zh: "感谢您访问我的作品集页面！如果您正在寻找一位经验丰富、有才华的前端开发人员，可以帮助您创建独特而功能强大的网络界面，那么您来对地方了。",
    ru: "Спасибо за посещение моей страницы портфолио! Если вы ищете опытного и талантливого фронтенд-разработчика, который поможет вам создать уникальный и функциональный веб-интерфейс, то вы попали по адресу.",
    es: "¡Gracias por visitar mi página de portafolio! Si estás buscando un desarrollador frontend experimentado y talentoso que pueda ayudarte a crear una interfaz web única y funcional, entonces estás en el lugar correcto.",
    pt: "Obrigado por visitar minha página de portfólio! Se você está procurando um desenvolvedor frontend experiente e talentoso que possa ajudá-lo a criar uma interface web única e funcional, então você está no lugar certo.",
    be: "Дзякуй за наведванне маёй старонкі партфоліа! Калі вы шукаеце вопытнага і таланавітага фронтэнд-распрацоўшчыка, які зможа дапамагчы вам стварыць унікальны і функцыянальны вэб-інтэрфейс, то вы знайшлі правільны варыянт.",
  },
  contacts_description_2: {
    en: "If you have any questions, comments, or proposals, please do not hesitate to contact me through the contact form. I will be happy to discuss all the details.",
    zh: "如果您有任何问题、意见或建议，请通过联系表格与我联系。我将很乐意讨论所有细节。",
    ru: "Если у вас есть вопросы, комментарии или предложения, пожалуйста, не стесняйтесь связаться со мной через контактную форму. Я буду рад обсудить все детали.",
    es: "Si tienes alguna pregunta, comentario o propuesta, no dudes en ponerte en contacto conmigo a través del formulario de contacto. Estaré encantado de discutir todos los detalles.",
    pt: "Se você tiver alguma dúvida, comentário ou proposta, não hesite em entrar em contato comigo através do formulário de contato. Ficarei feliz em discutir todos os detalhes.",
    be: "Калі ў вас ёсць пытанні, каментары або прапановы, калі ласка, не саромейцеся звязацца да мяне праз кантактную форму. Я буду рады абмеркаваць усе дэталі.",
  },
  contacts_write: {
    en: "Write",
    zh: "写信",
    ru: "Написать",
    es: "Escribir",
    pt: "Escrever",
    be: "Напісаць",
  },
  contacts_contacts: {
    en: "Contacts",
    zh: "联系方式",
    ru: "Контакты",
    es: "Contactos",
    pt: "Contatos",
    be: "Кантакты",
  },
  contacts_name: {
    en: "Name",
    zh: "姓名",
    ru: "Имя",
    es: "Nombre",
    pt: "Nome",
    be: "Імя",
  },
  contacts_email: {
    en: "E-mail",
    zh: "电子邮件",
    ru: "E-mail",
    es: "Correo electrónico",
    pt: "E-mail",
    be: "E-mail",
  },
  contacts_message: {
    en: "Message",
    zh: "信息",
    ru: "Сообщение",
    es: "Mensaje",
    pt: "Mensagem",
    be: "Паведамленне",
  },
  contacts_send: {
    en: "Send",
    zh: "发送",
    ru: "Отправить",
    es: "Enviar",
    pt: "Enviar",
    be: "Адправіць",
  },
  contacts_sent_successfully: {
    en: "Email sent successfully.",
    zh: "邮件发送成功。",
    ru: "Письмо успешно отправлено.",
    es: "Correo electrónico enviado correctamente.",
    pt: "E-mail enviado com sucesso.",
    be: "Электронная пошта паспяхова адпраўлена.",
  },
  contacts_error_1: {
    en: "Failed to send email.",
    zh: "邮件发送失败。",
    ru: "Не удалось отправить письмо.",
    es: "Error al enviar el correo electrónico.",
    pt: "Falha ao enviar o e-mail.",
    be: "Не атрымалася адправіць электронную пошту.",
  },
  contacts_error_2: {
    en: "Too many requests. Please try again in:",
    zh: "请求过多。请稍后再试：",
    ru: "Слишком много запросов. Пожалуйста, повторите через:",
    es: "Demasiadas solicitudes. Por favor, inténtelo de nuevo en:",
    pt: "Demasiadas solicitações. Por favor, tente novamente em:",
    be: "Занадта многа запытаў. Калі ласка, паўтарыце праз:",
  },
  contacts_seconds: {
    en: "seconds",
    zh: "秒",
    ru: "секунд",
    es: "segundos",
    pt: "segundos",
    be: "секунд",
  },
  contacts_use_ctrl_enter: {
    en: "Use ctrl+enter to send",
    zh: "使用 ctrl+enter 发送",
    ru: "Используйте ctrl+enter для отправки",
    es: "Usar ctrl+enter para enviar",
    pt: "Usar ctrl+enter para enviar",
    be: "Выкарыстоўвайце ctrl+enter каб адправіць",
  },
  contacts_error_name_required: {
    en: "Name is required",
    zh: "姓名是必填项",
    ru: "Имя обязательно",
    es: "El nombre es obligatorio",
    pt: "Nome é obrigatório",
    be: "Імя абавязкова",
  },
  contacts_error_email_required: {
    en: "Email is required",
    zh: "电子邮件是必填项",
    ru: "E-mail обязателен",
    es: "El correo electrónico es obligatorio",
    pt: "E-mail é obrigatório",
    be: "E-mail абавязкова",
  },
  contacts_error_email_invalid: {
    en: "Invalid email",
    zh: "电子邮件无效",
    ru: "Некорректный e-mail",
    es: "Correo electrónico no válido",
    pt: "E-mail não válido",
    be: "Некарэктны e-mail",
  },
  contacts_error_message_required: {
    en: "Message is required",
    zh: "信息是必填项",
    ru: "Сообщение обязательно",
    es: "El mensaje es obligatorio",
    pt: "Mensagem é obrigatório",
    be: "Паведамленне абавязкова",
  },
}
