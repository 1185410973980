import React, { useEffect, useState } from "react"
import s from "./ScrollToTop.module.scss"
import { FaArrowUp } from "react-icons/fa"

export const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    setIsVisible(scrollTop > 100)
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div className={`${s.container} ${isVisible ? s.visible : ""}`} onClick={scrollToTop} id={'scrollToTop'}>
      <FaArrowUp />
    </div>
  )
}
