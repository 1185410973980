import { useEffect, FC } from "react"

const applyTheme = (isDarkTheme: boolean) => {
  document.documentElement.setAttribute("data-theme", isDarkTheme ? "dark" : "light")
}

export const Theme: FC = () => {
  const handleThemeChange = () => {
    const isDarkThemeEnabled = window.matchMedia("(prefers-color-scheme: dark)").matches
    applyTheme(isDarkThemeEnabled)
  }

  useEffect(() => {
    handleThemeChange()
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleThemeChange)

    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handleThemeChange)
    }
  }, [])

  return null
}
