import React, { FC } from "react"
import style from "./Main.module.scss"
import { Container } from "../../common/components/Container/Container"
import { Animation } from "../../common/components/Animation/Animation"
import ReactTypingEffect from "react-typing-effect"
import main from "../../assets/image/main.webp"
import { FiArrowDownCircle } from "react-icons/fi"
import { scrollToElement } from "../../common/utils/scrollToElement"
import { useLanguage } from "../../common/translations/translations"
import { Button } from "../../common/components/Button/Button"

export const Main: FC = () => {
  const { t } = useLanguage()

  return (
    <section className={style.mainBlock} id={"main"}>
      <Container>
        <div className={style.wrapper}>
          <Animation animation={"animate__zoomIn"} threshold={0.1}>
            <div className={style.photo} style={{ backgroundImage: `url(${main})` }}></div>
          </Animation>

          <div className={style.text}>
            <h1>{t("main_hi")}</h1>

            <div className={style.typingEffect}>
              <ReactTypingEffect
                text={[t("main_developer"), t("main_react"), t("main_redux"), t("main_pixijs")]}
                speed={100}
                typingDelay={100}
                eraseDelay={1000}
                eraseSpeed={100}
                cursor={"_"}
              />
            </div>

            <p>{t("main_welcome")}</p>

            <div className={style.cv}>
              <div className={style.buttonWrapper}>
                <Button onClick={() => scrollToElement("resume")}>{t("main_view_resume")}</Button>
              </div>

              <a onClick={() => scrollToElement("contacts")}>
                {t("main_contact_me")}
                <FiArrowDownCircle />
              </a>
            </div>
          </div>
        </div>

        <a onClick={() => scrollToElement("about")}>
          <div className={style.arrow}>↓</div>
        </a>
      </Container>
    </section>
  )
}
