export const scrollToElement = (value: string) => {
  const element = document.getElementById(value)

  const marginMap = new Map()

  marginMap.set("about", { desktop: 70, mobile: 0 })

  if (element) {
    const margins = marginMap.get(value) || { desktop: 30, mobile: 0 }

    const marginTop = window.innerWidth <= 600 ? margins.mobile : margins.desktop

    const elementPosition = element.getBoundingClientRect().top + window.scrollY
    window.scrollTo({
      top: elementPosition - marginTop,
      behavior: "smooth",
    })
  }
}
