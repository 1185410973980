import React, { FC } from "react"
import style from "./Resume.module.scss"
import { Container } from "../../common/components/Container/Container"
import { Animation } from "../../common/components/Animation/Animation"
import { ComponentHeader } from "../../common/components/ComponentHeader/ComponentHeader"
import { RsumeInfo } from "./ResumeInfo/RsumeInfo"
import { useLanguage } from "../../common/translations/translations"
import { Button } from "../../common/components/Button/Button"
import { SkillsLines } from "./SkillsLines/SkillsLines"

export const Resume: FC = () => {
  const { t } = useLanguage()

  const onButtonClick = () => {
    window.open("https://fedrr.com/cv/FedorovichSergeyCV.pdf", "_blank")
  }

  return (
    <section className={style.resumeBlock} id={"resume"}>
      <Container>
        <div className={style.wrapper}>
          <Animation animation={"animate__fadeInUp"} threshold={0.2}>
            <div className={style.title}>
              <h3>{t('resume_resume')}</h3>
              <ComponentHeader>{t('resume_summary')}</ComponentHeader>
            </div>
          </Animation>

          <RsumeInfo />

          <SkillsLines />

          <Animation animation={"animate__fadeInUp"} threshold={0.4}>
            <div className={style.buttonWrapper}>
              <Button theme={'secondary'} onClick={onButtonClick}>{t('resume_download_cv')}</Button>
            </div>
          </Animation>
        </div>
      </Container>
    </section>
  )
}
