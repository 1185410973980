import React, { FC } from "react"
import style from "../Resume.module.scss"
import { Animation } from "../../../common/components/Animation/Animation"
import { useLanguage } from "../../../common/translations/translations"

export const RsumeInfo: FC = () => {
  const { t } = useLanguage()

  return (
    <div className={style.resumes}>
      <Animation animation={"animate__fadeInUp"} threshold={0.1}>
        <div className={style.experience}>
          <h2>{t("resume_experience")}</h2>
          <div className={style.experienceInfo}>
            <h3>{t("resume_frontend_developer")}</h3>
            <h4>Sportdata Limited / {t("resume_june")} 2023 - {t("resume_current")}</h4>
            <p>
              {t("resume_sportdata")}
            </p>
            <hr />
            <h3>{t("resume_frontend_developer")}</h3>
            <h4>telegif.com / {t("resume_june")} 2022 - {t("resume_july")} 2023</h4>
            <p>
              {t("resume_telegif")}
            </p>
            <hr />
            <h3>{t("resume_technical_support_engineer")}</h3>
            <h4>sys.by / {t("resume_november")} 2021 - {t("resume_june")} 2022</h4>
            <p>
              {t("resume_sys")}
            </p>
            <hr />
          </div>
        </div>
      </Animation>

      <Animation animation={"animate__fadeInUp"} threshold={0.1}>
        <div className={style.education}>
          <h2>{t("resume_education")}</h2>
          <div className={style.educationInfo}>
            <h3>{t("resume_master")}</h3>
            <h4>{t("resume_bseu")} / 2017 - 2019</h4>
            <p>
              {t("resume_education_master")}
            </p>
            <hr />
            <h3>{t("resume_bachelor")}</h3>
            <h4>{t("resume_bseu")} / 2013 - 2017</h4>
            <p>
              {t("resume_education_bachelor")}
            </p>
            <hr />
          </div>
        </div>
      </Animation>
    </div>
  )
}
